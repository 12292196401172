@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: poppins-regular;
  src: url(../public/fonts/poppins-regular.ttf);
}


@font-face {
  font-family: NunitoSans-Regular;
  src: url(../public/fonts/NunitoSans-Regular.ttf);
}



body{
  background: #fff;
}
.mont-font {
  font-family: NunitoSans-Regular;
}

.mulish-font {
  font-family: NunitoSans-Regular;
}

.nunito-font {
  font-family: 'NunitoSans-Regular';
}

body{
  font-family: 'NunitoSans-Regular';
}
.body-v1 {
  font-family: "NunitoSans-Regular";
}

html {
  overflow: auto !important;
}

.body-v2 {
  font-family: NunitoSans-Regular;
}

.force-w {
  width: 3rem !important;
  margin-right: 5px;
}

.margin-001 {
  margin: 0px 30px !important;
}
.theme-grad-app{
  background: linear-gradient(292.15deg, #081A24 -11.48%, #0B4263 99.44%);
}
.theme-grad-001 {
  background: linear-gradient(292.15deg, #081A24 -11.48%, #0B4263 99.44%);
  height: 100%;
}

.theme-grad-002 {
  background: linear-gradient(180deg, #dfaa7b 0%, #f5d79f 100%);
}

.theme-grad-003 {
  background: linear-gradient(180deg, #0dcd71 0%, #98f33d 65.1%);
  border-radius: 15px;
}

.theme-grad-006 {
  background: linear-gradient(180deg, #dbdbdb 0%, #ffffff 50%, #f8f8f8 100%);
}

.theme-grad-007 {
  background: linear-gradient(243.98deg, #081A24 0%, #0B4263 100%);
}

.new-001 {
  position: absolute;
  right: 14px;
  z-index: 10;
  font-size: 19px;
  font-weight: 700;
  top: 0;
}

.font-small-001 {
  font-size: 11px;
}

.addon-001 {
  font-style: normal;
  font-weight: 700;
font-size: 12px;
line-height: 16px;
letter-spacing: 0.2px;
color: #4C4C4C;
  padding: 0px;
  border: double 2px transparent;
  border-radius: 5px;
  /* background-image: linear-gradient(white, white), radial-gradient(circle at top left, rgba(223, 170, 123, 1), rgba(245, 215, 159, 1)); */
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-right: 4px;
  margin-bottom: 7px;
}

.addon-001 p {
  padding: 8px 8px;
}

.addon-002 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #E6F5FF;
  padding: 0px;
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(#0b3f5f, #0b3f5f), radial-gradient(circle at top left, rgba(223, 170, 123, 1), rgba(245, 215, 159, 1));
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-right: 10px;
}

.addon-002 p {
  padding: 8px 10px;
}

.bg-gray-002 {
  background-color: #757a811f;
}

.bg-blue-001 {
  background: #0b4263;
}

.text-blue-001 {
  color: #0b4263;
}

.text-blue001 {
  color: #E1EDF5;
}

.text-gold-002 {
  background: #dfaa7b;
}

.nav1 .active {
  border-bottom: 1px solid #F5D79F;
  background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 0px
}

.text-gold {
 color: #FFCB00;
}

.text-011 {
  color: #DFAA7B;
}

.text-010 {
  font-size: 10px;
}

.transform-001 {
  transform: rotate(-0.29deg);
}

.header-01 {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 100px;
}

.text-blue-002 {
  color: linear-gradient(180deg, #081924 0%, #0b4263 100%);
}

.text-blue-003 {
  background-color: #393e44;
}

.bg-white-001 {
  background: #dfaa7b;
}

.border-white-001 {
  border: 1px solid #f5d79f;
}

.border-white-002 {
  border-bottom: 1px solid #1E2A3A;
  border-radius: 0 !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.text-light-white {
  color: #CBD5E0;
}

.btn-001 {
  background: #FECC1A;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.05);
  border-radius: 33px;

}

.input-001 {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 34px;
}

.border-white-003 {
  border: 1px solid #E6F5FF;
}

.text-white-003 {
  color: #ECECEC;
}

.input-gold [type='radio']:checked {
  background-image: url(../public/Ellipse2.png);
  background: #c5900b !important;
  /* border: 2px solid #636262; */
}

.input-gold [type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
  border-color: transparent;
  background-color: #c5900b !important;
}

.input-gold [type='checkbox'],
[type='radio'] {
  color: #c5900b;
}

[type='radio']:checked {
  background-image: url(../public/Ellipse2.png);
  background: #0b4263;
  /* border: 2px solid #636262; */
}

[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
  border-color: transparent;
  background-color: #0b4263;
}

.border-002 {
  border: 1px solid #dfaa7b;
}

optgroup {
  color: #000;
  font-size: 14px;
}

optgroup option {
  border-bottom: 1px solid;
  color: #000;
  font-size: 14px;
}

.border-007 {
  border-bottom: 2px solid #EAC18E;
  border-radius: 0px;
  width: fit-content;
}

::-moz-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

.border-008 {
  border-bottom: 2px solid #fff;
  border-radius: 0px
}

.border-009 {
  border: none;
  border-bottom: 1px solid #fff;
}

.li-001 {
  list-style: auto;
}

.border-003 {
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.border--004 {
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border--005 {
  border-radius: 10px;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.text-black-001 {
  color: #3a3128;
}

.theme-grad-004 {
  background: linear-gradient(180deg,
      #f5d79f 8.85%,
      #f5d79f 31.77%,
      #f5d79f 59.37%,
      #eac08c 84.9%);
}

.theme-grad-005 {
  background: linear-gradient(180deg,
      #081924 0%,
      #081924 0.01%,
      #0b4263 64.58%,
      #000000 97.4%);
}

.silverTheme {
  background: linear-gradient(180deg, #e9e9e9 0%, #ffffff 50%, #f8f8f8 100%);
}

.silverBuyPanel {
  background: linear-gradient(180deg, #dbdbdb 0%, #ffffff 50%, #f8f8f8 100%);
}

.home-theme-001 {
  background: linear-gradient(180deg, #081A24 0%, #0B4263 100%);
  height: 100%;
}

.home-theme-app {
  background: linear-gradient(180deg, #081A24 0%, #0B4263 100%);
}

.bg-gold-001 {
  background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%);
}

.bg-gray-001 {
  background: linear-gradient(180deg, #e0e0e0 0%, #e0e0e0 50%, #e0e0e0 100%) !important;
}

.margin-auto-001 {
  margin: 0 auto;
}

.footer-back-001 {
  background: #f5fbff;
}
.min-height-001{
  min-height: 65%;
}
.bg-img-001 {
  background: url(../public/backgound1.png);
  background-size: cover;
  background-position: center;
}

.loader-app-001 {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #DFAA7B;
  /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.theme-black {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 83%);
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #0b2e43 !important;
  stroke-linecap: round;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #0b2e43 !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #e8b85f!important;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.text-001 {
  font-size: 10px;
}
.back-app-001{
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.tabs-component1 [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  padding: 0px 0px;
}

.tabs-component1 [role="tablist"] button {
  border-bottom: 2px solid;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: #101F29;
  opacity: 0.5;
}

.tabs-component1 [role="tablist"] button.active,
.tabs-component1 [role="tablist"] button:focus,
.tabs-component1 [role="tablist"] button:hover {
  /* border-width: 1px; */
  border-color: #101F29;
}

.tabs-component1 [role="tablist"] button.active {
  color: #101F29;
  opacity: 1;
}

.tabs-component1 [role="tabpanel"] {
  text-align: left;
}

.tabs-component1.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component1.vertical [role="tablist"] {
  flex-direction: column;
}

.tabs-component1.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component1 [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component1.vertical [role="tablist"] button.active,
.tabs-component1.vertical [role="tablist"] button:focus,
.tabs-component1.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}


.tabs-component2 [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 0px 40px;
}

.tabs-component2 [role="tablist"] button {
  border-bottom: 1px solid;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-family: 'NunitoSans-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: #4C4C4C;
}

.tabs-component2 [role="tablist"] button.active,
.tabs-component2 [role="tablist"] button:focus,
.tabs-component2 [role="tablist"] button:hover {
  /* border-width: 1px; */
  border-color: #FFCB00;
}

.tabs-component2 [role="tablist"] button.active {
  color: #FFCB00;
}

.tabs-component2 [role="tabpanel"] {
  text-align: left;
}

.tabs-component2.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component2.vertical [role="tablist"] {
  flex-direction: column;
}

.tabs-component2.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component2 [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component2.vertical [role="tablist"] button.active,
.tabs-component2.vertical [role="tablist"] button:focus,
.tabs-component2.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}



.tabs-component3 [role="tablist"] {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.tabs-component3 [role="tablist"] button {
  display: block;
  padding: 0rem 1.3rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-family: 'NunitoSans-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 39px;
  border-radius: 3px;
  letter-spacing: 0.2px;
  background: #b6b3b3;
  color: #423f3f;
}

.tabs-component3 [role="tablist"] button.active,
.tabs-component3 [role="tablist"] button:focus,
.tabs-component3 [role="tablist"] button:hover {
  /* border-width: 1px; */
  /* border-color: #F5D79F; */
}

.tabs-component3 [role="tablist"] button.active {
  color: #423f3f;
  background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%);
}

.tabs-component3 [role="tabpanel"] {
  text-align: left;
}

.tabs-component.vertical3 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical3 [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical3 [role="tabpanel"] {
  flex: 1;
}

.tabs-component3 [role="tablist"] button {
  margin: 0;
}

.tabs-component.vertical3 [role="tablist"] button.active,
.tabs-component.vertical3 [role="tablist"] button:focus,
.tabs-component.vertical3 [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}


.tabs-component4 [role="tablist"] {
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  background: #fff;
  padding: 7px 0 0;
}

.tabs-component4 [role="tablist"] button {
  display: block;
  padding: 0rem 1.3rem;
  cursor: pointer;
  margin: 0 25px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-family: 'NunitoSans-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 54px;
  /* border-radius: 3px; */
  letter-spacing: 0.2px;
  background: transparent;
  color: #4C4C4C;

}

.tabs-component4 [role="tablist"] button.active,
.tabs-component4 [role="tablist"] button:focus,
.tabs-component4 [role="tablist"] button:hover {
  /* border-width: 1px;
  border-color: #F5D79F; */
  border-bottom: 3px solid #FFCB00;
}

.tabs-component4 [role="tablist"] button.active {
  color: #FFCB00;
  /* background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%); */
}

.tabs-component4 [role="tabpanel"] {
  text-align: left;
}

.tabs-component.vertical4 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical4 [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical4 [role="tabpanel"] {
  flex: 1;
}

.tabs-component4 [role="tablist"] button {
  margin: 0 25px 0px 25px;
  ;
}

.tabs-component.vertical4 [role="tablist"] button.active,
.tabs-component.vertical4 [role="tablist"] button:focus,
.tabs-component.vertical4 [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}


.tabs-component5 [role="tablist"] {
  /* display: flex; */
  justify-content: space-around;
  position: relative;
  padding: 7px 0 0;
}

.tabs-component5 [role="tablist"] button {
  display: block;
  padding: 0rem 1.3rem;
  cursor: pointer;
  margin: 0 25px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-family: 'NunitoSans-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  /* border-radius: 3px; */
  letter-spacing: 0.2px;
  background: transparent;
  color: #fff;

}

.tabs-component5 [role="tablist"] button.active,
.tabs-component5 [role="tablist"] button:focus,
.tabs-component5 [role="tablist"] button:hover {
  /* border-width: 1px;
  border-color: #F5D79F; */
  border-bottom: 3px solid #FFCB00;
}

.tabs-component5 [role="tablist"] button.active {
  color: #FFCB00;
  /* background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%); */
}

.tabs-component5 [role="tabpanel"] {
  text-align: left;
}

.tabs-component.vertical5 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical5 [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical5 [role="tabpanel"] {
  flex: 1;
}

.tabs-component5 [role="tablist"] button {
  margin: 0 25px 0px 25px;
  ;
}

.tabs-component.vertical5 [role="tablist"] button.active,
.tabs-component.vertical5 [role="tablist"] button:focus,
.tabs-component.vertical5 [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}


.tabs-component [role="tablist"] {
  position: relative;
  border-radius: 0;
  background: #fff;
  padding: 20px 10px 0;
}

.tabs-component [role="tablist"] button {
  border-right: 2px solid #FEEBC7;
  border-style: solid;
  border-color: transparent;
  /* border-radius: 0.75rem; */
  display: block;
  padding: 0rem 3rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  transition: border 0.2s ease-in-out 0s;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: 0.2px;
  color: #4C4C4C;
  background: #fff;
  /* opacity: 0.5; */
}

.tabs-component [role="tablist"] button.active,
.tabs-component [role="tablist"] button:focus,
.tabs-component [role="tablist"] button:hover {
   /* border-width: 1px;
   border-color: #FFCB00; */
}

.tabs-component [role="tablist"] button.active {
  color: #FFCB00;
  opacity: 1;
  border-bottom: 2px solid #FFCB00;


}

.tabs-component [role="tabpanel"] {
  text-align: left;
}

.tabs-component.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component [role="tablist"] button {
  margin: 0;
}

.tabs-component.vertical [role="tablist"] button.active,
.tabs-component.vertical [role="tablist"] button:focus,
.tabs-component.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
  border-bottom: 1px solid;
}

.pad-001 [role="tablist"] {
  padding: 0px 0px !important;
}

.pad-001 [role="tablist"] button {
  padding: 0px 0px !important;
  margin: 40px 0px;
}

.border-009:focus {
  border-bottom: 1px solid #fff !important;
}

.bg-dark-002 {
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.bg-silver-001 {
  background: linear-gradient(180deg, #6e6e6e 0%, #f7f4ef 100%);
}

.bg-dark-004 {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 1px 2px rgb(0 0 0 / 30%);
  border-radius: 5px;
}

.text-black-002 {
  color: #393E44;
}

.bg-dark-003 {
  background: #393E44;
}

.text-red-001 {
  color: #FF0000;
}

.h-screen-001 {
  min-height: 100vh;
}

.border-006 {
  border: 1px solid #393E44;
}

.mti-0 {
  margin-top: 0px !important;
}

.grad-001 {
  background: linear-gradient(180deg, #DFAA7B 0%, #F5D79F 100%);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide1 img {
  display: block;
  /* height: 100px; */
  object-fit: cover;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0px !important;
  height: 29px !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: #e9ecef;
  font-size: 30px !important;
  font-weight: 800;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  height: 29px !important;
  right: -8px !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #e9ecef;
  font-size: 30px !important;
  font-weight: 800;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important
}



.dot1 .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #E6F5FF !important;
}

.dot2 .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #fff !important;
}


 .dot1  .swiper-pagination{
  bottom: 20px !important;
}

.dot-app-001 .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #FFCB00 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.abs-001 {
  position: absolute;
  width: 72%;
  top: 30%;
  left: 14%;
  z-index: 1;
}

.sm\:text-4xl {
  line-height: 3.5rem !important;
}



.right-001 {
  right: 0;
}

.force-w {
  width: 3rem !important;
  height: 3rem;
  margin-right: 15px;
}

.visible-001 {
  display: none;
}

.line-001 {
  line-height: 56px !important;
}

.line-002 {
  line-height: 32px !important;
}

.light-text-001 {
  color: #CEDAE0;
}

.light-text-002 {
  color: #E6F5FF;
}

.weight-001 {
  font-weight: 900;
}

.bg-rose-warm {
  background: #FFFAF5;
}

.text-dark-blue {
  color: #101F29;
}

.text-light-blue {
  color: #1E2A3A;
}

.heading-001 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.1px;
}

.fea-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #101F29;
}

.fea-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;

}

.graph-pera-001 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #CEDAE0;
}

.red-box {
  background: #FF1515;
  width: 18px;
  height: 18px;
  margin-right: 0.8rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.text-green-001 {
  color: #3EC000;
}

.how-heading-001 {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #101F29;
}

.how-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  color: #1E2A3A;
  padding: 0 20px;
}

.motive-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.box-001 {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delivery-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
}

.delivery-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #1E2A3A;
}

.mailing-heading {
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 140%;
  text-align: center;
  color: #EBF7FF;
}

.mailing-pera {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #E6F5FF;
}

.py-150 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1E2A3A;
}

.how-about-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  min-height: 55px;
  letter-spacing: 0.1px;
  color: #4c4c4c;
  text-align: center;
}

.how-about-pera {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #4c4c4c;
  text-align: center;
}

.redeem-pera {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #101F29;
}

.border-dark-001 {
  border: 1px solid #393e43;
}

.abs-motive-001 {
  top: -140px;
  position: absolute;
  width: 100%;
}

.abs-motive-002 {
  top: 100px;
  position: absolute;
  width: 100%;
}

.abs-motive-003 {
  bottom: 80px;
  position: absolute;
  width: 100%;
}

.abs-motive-004 {
  bottom: -20px;
  position: absolute;
  width: 100%;
}

.abs-left-30 {
  left: 30%;
}

.gold {
  background-color: #e8a50f;
  width: 18px;
  height: 18px;
  margin-right: 0.8rem;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.silver {
  background-color: #a7bcce;
  width: 18px;
  height: 18px;
  margin-right: 0.8rem;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.nav {
  position: fixed;
  width: 100%;
  /* height: 10rem; */
  z-index: 30;
  border: none !important;
}

.z-110 {
  z-index: 110;
}

.warm-blue-001 {
  color: #EBF7FF;
}

.black-light-001 {
  color: #393E44;
}

.app-p1 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #EBF7FF;
}

.app-span1 {
  color: #F5D79F;
  text-decoration: underline;
}

.app-p2 {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #E6F5FF;
  margin-top: 20px;
}

.gold-btn-001{
  background: #FFCB00;
}
.bg-white-app{
  background: #E5E5E5;
}
.gold-text-001{
  color: #FFCB00;;
}
.border-gray-001{
  border: 0.5px solid rgba(76, 76, 76, 0.5);
}
.app-text-light{
  color: #f3f3f3;
}
.app-text-dark{
  color: #4C4C4C;
}
.app-bg-dark{
  background: #4C4C4C;
}
.app-grad-dark{
  background: linear-gradient(292.15deg, #081A24 -11.48%, #0B4263 99.44%);
}
.app-border-dark{
  border-bottom: 1px solid #4C4C4C !important;
  border-radius: 0 !important;
}
.app-box-001{
  background: #FFFFFF;
    border-radius: 22px;
    background: linear-gradient(144.96deg, #F7F7F7 -4.52%, #E4E4E4 179.88%);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
}
.foot-grad-001{
  background: linear-gradient(292.15deg, #081A24 -11.48%, #0B4263 99.44%);
}
.app-shadow-001{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
}
 .dot4 .swiper-pagination{
  bottom: 4px !important;
}
.blog-slider-p1{
  font-style: normal;
font-weight: 700;
font-size: 7px;
    line-height: 8px;
    letter-spacing: 0.1px;
    color: #4C4C4C;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
}
.app-text-green{
  color:#3EC000;
}
.app-font-text{
  font-size: 10px;
}
.app-margin-001 [role="tablist"] {
  position: relative;
  border-radius: 0;
  background: #fff;
  padding: 0px 10px 0 !important;
}
.app-font-002{
  font-size: 10px;
  line-height: 14px;
}

.theme-gray-bg{
  background: #E5E5E5;
}

.theme-yellow-bg{
  background: #FFCB00;
}

.theme-shadow{
  box-shadow: 0px 0px 22px -4px rgba(0, 0, 0, 0.1);
}

.backlogo{
  top: -24%;
    left: 0%;
    height: 28rem;
}
.backlogo2{
    top: 1%;
      right: 0%;
      height: 35rem;
}
.backlogo3{
    top: -6%;
    left: 3%;
    height: 18rem;
}
.nav1{
  background-size: cover;
  background-position: bottom;
  padding-bottom: 8px;
  border: none !important;
}

.theme-lightYellow-bg{
  background: #FFFCEF;
}

.new-border{
  border: 1px solid #767474;
    border-radius: 8px;
}
.swiper-pagination-bullet-active{
  background: #000 !important;
  width: 12px !important;
  height: 12px !important;
  margin-top: 12px !important;
}
@media only screen and (max-width: 1350px) {
  .abs-motive-001 {
    position: initial;
    width: 100%;
  }

  .abs-motive-002 {
    position: initial;
    width: 100%;
  }

  .abs-motive-003 {
    position: initial;
    width: 100%;
  }

  .abs-motive-004 {
    position: initial;
    width: 100%;
  }

  .abs-left {
    position: initial;
    display: inline-block;
    margin-top: 20px;
    padding: 15px;
  }

  .abs-right {
    position: initial;
    display: inline-block;
    float: right;
    margin-top: 20px;
    padding: 15px;
  }

  .abs-center {
    position: initial;
    display: inline-block;
    margin: 0 auto;
    display: block;
    padding: 15px;
  }

  

  .motive-ring {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .visible-003 {
    display: none;
  }

  .hide-banner {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .line-001 {
    line-height: 35px !important;
  }

  .height-001 {
    height: 100%;
  }

  .hide-banner {
    display: none;
  }

}

@media only screen and (min-width: 776px) {
  .hide-table-002 {
    display: none;
  }
}

@media only screen and (max-width: 776px) {
  .hide-table {
    display: none;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .pt-120 {
    padding-top: 100px;
  }

  .bg-img-001 {
    background: none;
  }

  .hide-menu {
    display: none;
  }

  .visible-002 {
    display: none;
  }

  .visible-001 {
    display: inline-block;
  }

  .right-001 {
    left: 0;
  }

  .hide-001 {
    display: none;
  }

  .abs-001 {
    display: none
  }

  .tabs-component2 [role="tablist"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 0px 0px;
  }

  .tabs-component2 [role="tablist"] button {
    font-size: 16px;
  }

  .pad-001 [role="tablist"] button {
    padding: 0px 0px !important;
    margin: 20px 10px;
  }

  .line-001 {
    line-height: 30px !important;
  }

  .mailing-heading {
    font-size: 25px;
    line-height: 140%;
  }

  .mailing-pera {
    font-size: 16px;
    line-height: 26px;
  }

  .heading-001 {
    font-size: 25px;
    line-height: 40px;
  }

  .abs-center {
    position: initial;
    margin: 0 auto;
    display: initial;
    padding: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .pt-120 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 567px) {
  .py-150 {
    padding-top: 0px;
    padding-bottom: 200px;
  }
  .how-about-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;}
  .heading-001 {
    font-size: 17px;
    line-height: 40px;
}
  .block-001 {
    display: block;
  }

  .tabs-component [role="tablist"] button {
    font-size: 14px;
  }

  .abs-left {
    padding: 0px;
  }

  .tabs-component [role="tablist"] button {
    padding: 0rem 1rem;
  }

  .abs-right {
    padding: 0;
  }

  .abs-center {
    padding: 0;
  }

  .redeem-pera {
    font-size: 16px;
    line-height: 18px;
  }

  .foot-social {
    display: flex;
    justify-content: space-around;
  }

  .tabs-component1 [role="tablist"] button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 5px 0;
    font-size: 11px;
  }

  .pad-001 [role="tablist"] button {
    padding: 0px 0px !important;
    margin: 0px 0px;
    margin-top: 15px;
  }

  .sm\:text-4xl {
    line-height: 2.0rem !important;

  }

  .force-w {
    width: 2.5rem !important;
    height: 2.5rem;
    margin-right: 15px;
  }

  .pt-001 {
    padding-top: 0 !important;
  }

  .hidden-001 {
    display: none;
  }

  .py-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .tabs-component2 [role="tablist"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 0px 0px;
  }

  .tabs-component2 [role="tablist"] button {
    font-size: 12px;
    line-height: 36px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 31%
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: -5px !important;
    height: 29px !important;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #e9ecef !important;
    font-size: 20px !important;
    font-weight: 800;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    color: #e9ecef !important;
    font-size: 20px !important;
    font-weight: 800;
  }

  .li-font-001 {
    font-size: 10px;
  }
}

.loader {
  border-top-color: #cb993a;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 380px){
.force-w {
    width: 2.2rem !important;
    height: 2.2rem;
    margin-right: 14px;
}
}